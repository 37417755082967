import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  return (
    // <
    <></>
    //   data-theme="dark"
    //   p={["40px 16px", null, "56px 40px 32px 40px"]}
    //   position="relative"
    //   {...props}
    //   justifyContent="center"
    // >
    //   <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
    //     <StyledIconMobileContainer display={["block", null, "none"]}>
    //       <LogoWithTextIcon width="130px" />
    //     </StyledIconMobileContainer>
    //     <Flex
    //       order={[2, null, 1]}
    //       flexDirection={["column", null, "row"]}
    //       justifyContent="space-between"
    //       alignItems="flex-start"
    //       mb={["42px", null, "36px"]}
    //     >
    //       {items?.map((item) => (
    //         <StyledList key={item.label}>
    //           <StyledListItem>{item.label}</StyledListItem>
    //           {item.items?.map(({ label, href, isHighlighted = false }) => (
    //             <StyledListItem key={label}>
    //               {href ? (
    //                 <Link
    //                   data-theme="dark"
    //                   href={href}
    //                   target="_blank"
    //                   rel="noreferrer noopener"
    //                   color={isHighlighted ? vars.colors.warning : "text"}
    //                   bold={false}
    //                 >
    //                   {label}
    //                 </Link>
    //               ) : (
    //                 <StyledText>{label}</StyledText>
    //               )}
    //             </StyledListItem>
    //           ))}
    //         </StyledList>
    //       ))}
    //       <Box display={["none", null, "block"]}>
    //         <LogoWithTextIcon width="160px" />
    //       </Box>
    //     </Flex>
    //     <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
    //     <StyledToolsContainer
    //       data-theme="dark"
    //       order={[1, null, 3]}
    //       flexDirection={["column", null, "row"]}
    //       justifyContent="space-between"
    //     >
    //       <Flex order={[2, null, 1]} alignItems="center">
    //         {isMounted && <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />}
    //         <LangSelector
    //           currentLang={currentLang}
    //           langs={langs}
    //           setLang={setLang}
    //           color="textSubtle"
    //           dropdownPosition="top-right"
    //         />
    //       </Flex>
    //       <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center">
    //         <Box mr="20px">
    //           <CakePrice chainId={chainId} cakePriceUsd={cakePriceUsd} color="textSubtle" />
    //         </Box>
    //         <Button
    //           data-theme={isDark ? "dark" : "light"}
    //           as="a"
    //           href={buyCakeLink}
    //           target="_blank"
    //           scale="sm"
    //           endIcon={<ArrowForwardIcon color="backgroundAlt" />}
    //         >
    //           {buyCakeLabel}
    //         </Button>
    //       </Flex>
    //     </StyledToolsContainer>
    //   </Flex>
    // </StyledFooter>
  );
};

export default MenuItem;
